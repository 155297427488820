<template>
  <v-app>
    <NavBar />

    <v-main class="bg-grey">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar";

export default {
  name: "App",

  components: {
    NavBar,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
.bg-grey {
  background-color: #f5f5f5;
}
</style>
