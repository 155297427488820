import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { auth } from '@/firebase/firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/Register.vue'),
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: () => import('../views/campaign/List.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign/create',
    name: 'AddCampaign',
    component: () => import('../views/campaign/Create.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/campaign/:id',
    name: 'Campaign',
    component: () => import('../views/campaign/Show.vue'),
  },
  {
    path: '/campaign/edit/:id',
    name: 'editCampaign',
    component: () => import('../views/campaign/Detail.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/user/List.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/report/List.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/reports/censures/operators',
    name: 'CensuresOperator',
    component: () => import('../views/report/censure/Operators.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/reports/censures/remaining',
    name: 'CensuresRemaining',
    component: () => import('../views/report/censure/Remaining.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/reports/censures/problem',
    name: 'CensuresWithProblem',
    component: () => import('../views/report/censure/Problem.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/profile/Detail.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/review",
  //   name: "PanelReview",
  //   component: () => import("../views/panel/Review.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/panel',
    name: 'Panel',
    component: () => import('../views/panel/List.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/devpanel',
    name: 'DevPanel',
    component: () => import('../views/dev/Index.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some((rec) => rec.meta.requiresAuth);
  const adminRequired = to.matched.some((rec) => rec.meta.requiresAdmin);
  if (authRequired) {
    const user = auth.currentUser;
    if (user) {
      if (adminRequired) {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            if (idTokenResult.claims.role === 'admin') {
              next();
            } else {
              next({ name: 'Home' });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        next();
      }
    } else {
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});

export default router;
