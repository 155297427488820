import Vue from 'vue';
import Vuex from 'vuex';
import {
  db,
  getDoc,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  Timestamp,
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  updateDoc,
  listAll,
  deleteObject,
} from '@/firebase/firebase';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    feedback: null,
    user: null,
    campaign: null,
    campaigns: null,
    censures: null,
  },

  getters: {
    getLoading(state) {
      return state.loading;
    },
    getFeedback(state) {
      return state.feedback;
    },
    getUser(state) {
      return state.user;
    },
    getCampaign(state) {
      return state.campaign;
    },
    getCampaigns(state) {
      return state.campaigns;
    },
    getCensures(state) {
      return state.censures;
    },
  },

  mutations: {
    updateLoading(state, loading) {
      state.loading = loading;
    },
    updateFeedback(state, payload) {
      state.feedback = payload;
    },
    updateUser(state, payload) {
      state.user = payload;
    },
    updateCampaign(state, payload) {
      state.campaign = payload;
    },
    updateCampaigns(state, payload) {
      state.campaigns = payload;
    },
    updateCensures(state, payload) {
      state.censures = payload;
    },
  },

  actions: {
    // 1.0 fetch current login user
    async loadCurrentUser(context, user_uid) {
      context.commit('updateLoading', true);
      console.log('1.0-a: starting loading Current User from firestore');
      try {
        const docRef = doc(db, 'users', user_uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          data.id = docSnap.id;
          context.commit('updateUser', data);
          console.log('1.0-b: current user loaded');
          // console.log("VUEX user", context.state.user);
        } else {
          console.log('No such document!');
        }
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
    },

    // 1.1 add loged user to firebase
    async addUserToFirestore(context, userRegistered) {
      context.commit('updateLoading', true);
      console.log('1.1-a: starting add Current User to firestore');

      try {
        await setDoc(doc(db, 'users', userRegistered.id), userRegistered);
        context.commit('updateUser', userRegistered);
        console.log('1.1-b: current user created in firestore');
      } catch (err) {
        console.log(err);
      }

      context.commit('updateLoading', false);
    },

    //2.0 load all campaigns from user
    async getAllCampaignsFromUser(context, userId) {
      context.commit('updateLoading', true);
      console.log(
        '2.0-a: starting loading all campaigns from User in firestore'
      );

      try {
        const q = query(
          collection(db, 'campaigns'),
          where('ownerId', '==', userId),
          orderBy('createdAt', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const loadedCampaigns = [];
        querySnapshot.forEach((doc) => {
          const newCampaign = doc.data();
          newCampaign.id = doc.id;
          loadedCampaigns.push(newCampaign);
        });
        context.commit('updateCampaigns', loadedCampaigns);
        console.log('2.0-b: all campaigns loaded from firestore');
        // console.log(loadedCampaigns);
      } catch (err) {
        console.log(err);
      }

      context.commit('updateLoading', false);
    },

    //2.1 load specific single campaign from firestore
    async getCampaignFromFirestore(context, campaignId) {
      context.commit('updateLoading', true);
      console.log('2.1-a: starting loading single campaign from firestore');

      try {
        const docRef = doc(db, 'campaigns', campaignId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const loadedCampaign = docSnap.data();
          loadedCampaign.id = docSnap.id;

          // GAMBI -> APAGAR
          if (loadedCampaign.censuresData) {
            loadedCampaign.censuresData.forEach((censure) => {
              censure.veiculacao = censure.veiculacao
                .toDate()
                .toLocaleDateString('pt-BR');
            });
          }
          // end GAMBI

          context.commit('updateCampaign', loadedCampaign);
        } else {
          console.log('Não foi possivel ler a campanha especificada.');
        }

        console.log('2.1-b: campaign loaded from firestore');
        // console.log(loadedCampaign);
      } catch (err) {
        console.log(err);
      }

      context.commit('updateLoading', false);
    },

    //2.2 add single campaign to firestore
    async saveCampaignToFirestore(context, newCampaign) {
      context.commit('updateLoading', true);
      console.log('2.1-a: starting loading single campaign from firestore');

      try {
        const id = newCampaign.slugId;
        delete newCampaign.slugId;
        newCampaign.createdAt = await Timestamp.now();
        await setDoc(doc(db, 'campaigns', id), newCampaign);
        newCampaign.id = id;
        context.commit('updateCampaigns', null);
        console.log('2.2-b: campaign added to firestore');
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
      return newCampaign;
    },

    //2.3 add storage data from specific doc
    async saveFilesToStorage(context, payload) {
      context.commit('updateLoading', true);
      console.log('2.3-a: starting upload file data to storage');

      try {
        // console.log("payload", payload);
        const txtFileName = `campaigns/${payload.id}/texts/${payload.txtFile.name}`;
        const txtRef = ref(storage, txtFileName);
        const txtSnapshot = await uploadBytes(txtRef, payload.txtFile);
        const txtUrl = await getDownloadURL(ref(storage, txtSnapshot.ref));
        // console.log("txt url", txtUrl);
        const imgFileName = `campaigns/${payload.id}/images/${payload.imgFile.name}`;
        const imgRef = ref(storage, imgFileName);
        const imgSnapshot = await uploadBytes(imgRef, payload.imgFile);
        const imgUrl = await getDownloadURL(ref(storage, imgSnapshot.ref));
        // console.log("img url", imgUrl);
        const campaignRef = doc(db, 'campaigns', payload.id);
        await updateDoc(campaignRef, {
          contentUrl: txtUrl,
          'pageData.bannerUrl': imgUrl,
        });
        console.log('2.3-b: files uploaded in firebase storage');
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
      // return newCampaign;
    },

    //2.4 add censure data to specific doc in firestore
    async addCensureDataToCampaignDoc(context, payload) {
      context.commit('updateLoading', true);
      console.log('2.4-a: starting update doc in firestore');
      let docId = null;
      try {
        const docRef = doc(db, 'campaigns', payload.campaignId);
        await updateDoc(docRef, { censuresData: payload.censuresData });
        docId = docRef.id;
        console.log('2.4-b: doc updated in firestore');
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
      return docId;
    },

    // 2.5 update campaign in this vuex state
    async UpdateVuexCampaign(context, newCampaign) {
      context.commit('updateLoading', true);
      console.log('2.5-a: starting update Campaign in vuex');
      console.log('vuex', newCampaign);

      try {
        context.commit('updateCampaign', newCampaign);
        console.log('2.5-b: Campaign updated in vuex');
      } catch (err) {
        console.log(err);
      }

      context.commit('updateLoading', false);
    },

    //2.6 add radio data to specific campaign doc in firestore
    async addRadioDataToCampaignDoc(context, payload) {
      context.commit('updateLoading', true);
      console.log('2.6-a: starting update radio doc data in firestore');
      let docId = null;
      try {
        const docRef = doc(db, 'campaigns', payload.campaignId);
        await updateDoc(docRef, { radiosData: payload.radiosData });
        docId = docRef.id;
        console.log('2.6-b: radio data updated to doc in firestore');
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
      return docId;
    },

    //2.7 load campaign and censuresData subCollection from firestore
    async loadCampaignWithCensuresData(context, campaignId) {
      context.commit('updateLoading', true);
      console.log(
        '2.7-a: starting loading single campaign with censures from firestore'
      );

      try {
        const docRef = doc(db, 'campaigns', campaignId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const loadedCampaign = docSnap.data();
          loadedCampaign.id = docSnap.id;
          const loadedCensures = [];
          const querySnapshot = await getDocs(
            collection(db, 'campaigns', docRef.id, 'censuresData')
          );
          querySnapshot.forEach((doc) => {
            const newCensure = {
              ...doc.data(),
              id: doc.id,
            };
            loadedCensures.push(newCensure);
          });

          if (loadedCensures.length)
            loadedCampaign.censuresData = loadedCensures;

          // GAMBI -> APAGAR
          if (loadedCampaign.censuresData) {
            console.log('ENTER GAMBI');
            loadedCampaign.censuresData.forEach((censure) => {
              censure.veiculacao = censure.veiculacao
                .toDate()
                .toLocaleDateString('pt-BR');
            });
          }
          // end GAMBI

          context.commit('updateCampaign', loadedCampaign);
          // console.log(loadedCampaign);
        } else {
          console.log('Não foi possivel ler a campanha especificada.');
        }

        console.log('2.7-b: campaign loaded from firestore');
        // console.log(loadedCampaign);
      } catch (err) {
        console.log(err);
      }

      context.commit('updateLoading', false);
    },

    //2.8 add censure data to subcolection of especific doc in firestore
    async addCensureDataToSubcollection(context, payload) {
      context.commit('updateLoading', true);
      console.log('2.8-a: starting add doc in firestore');
      try {
        const subColRef = collection(
          db,
          'campaigns',
          payload.campaignId,
          'censuresData'
        );

        payload.censuresData.forEach((obj) => {
          addDoc(subColRef, obj);
        });

        console.log('2.8-b: doc updated in firestore');
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
    },

    //2.9 update single censure doc to subcollection in firestore
    async updateSingleCensureInSubCollection(context, payload) {
      context.commit('updateLoading', true);
      console.log(
        '2.9-a: starting updating single censure to subcollection in firestore'
      );
      let docId = null;
      try {
        docId = payload.censure.id;
        delete payload.censure.id;
        await setDoc(
          doc(db, 'campaigns', payload.campaignId, 'censuresData', docId),
          payload.censure
        );
        console.log('2.9-b: single censure updated in firestore');
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
      return docId;
    },

    //2.10 add single censure doc to subcollection in firestore
    async addSingleCensureToSubCollection(context, payload) {
      context.commit('updateLoading', true);
      console.log(
        '2.10-a: starting adding single censure to subcollection in firestore'
      );
      let docId = null;
      try {
        delete payload.censure.id;
        const subColRef = collection(
          db,
          'campaigns',
          payload.campaignId,
          'censuresData'
        );
        const docRef = await addDoc(subColRef, payload.censure);
        docId = docRef.id;
        console.log('2.10-b: single censure added in firestore');
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
      return docId;
    },

    //2.11 delete single censure doc in subcollection in firestore
    async deleteSingleCensureFromSubCollection(context, payload) {
      context.commit('updateLoading', true);
      console.log(
        '2.11-a: starting deleting single censure from subcollection in firestore'
      );
      try {
        await deleteDoc(
          doc(
            db,
            'campaigns',
            payload.campaignId,
            'censuresData',
            payload.docId
          )
        );
        console.log('2.11-b: single censure deleted from firestore');
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
    },

    //2.12 load campaign and only published Censures
    async loadCampaignWithOnlyPublishedCensures(context, campaignId) {
      context.commit('updateLoading', true);
      console.log(
        '2.12-a: starting loading single campaign with published censures'
      );

      try {
        const docRef = doc(db, 'campaigns', campaignId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const loadedCampaign = docSnap.data();
          loadedCampaign.id = docSnap.id;
          const loadedCensures = [];
          const q = query(
            collection(db, 'campaigns', docRef.id, 'censuresData'),
            where('status', '>=', 2)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            const newCensure = {
              ...doc.data(),
              id: doc.id,
            };
            loadedCensures.push(newCensure);
          });

          if (loadedCensures.length)
            loadedCampaign.censuresData = loadedCensures;

          // GAMBI -> APAGAR
          if (loadedCampaign.censuresData) {
            loadedCampaign.censuresData.forEach((censure) => {
              censure.veiculacao = censure.veiculacao
                .toDate()
                .toLocaleDateString('pt-BR');
            });
          }
          // end GAMBI

          context.commit('updateCampaign', loadedCampaign);
          console.log(loadedCampaign);
        } else {
          console.log('Não foi possivel ler a campanha especificada.');
        }

        console.log('2.12-b: campaign loaded from firestore');
        // console.log(loadedCampaign);
      } catch (err) {
        console.log(err);
      }

      context.commit('updateLoading', false);
    },

    // 2.13 load all censures from campaign by
    async getCensuresbyRadio(context, payload) {
      context.commit('updateLoading', true);
      console.log('2.13-a: starting load censures by radio');
      let loadedCensures = [];
      try {
        const q = query(
          collection(db, 'campaigns', payload.campaignId, 'censuresData'),
          where('idSecom', '==', payload.radioData.idSecom)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const docRadio = { id: doc.id, ...doc.data() };
          loadedCensures.push(docRadio);
        });
        loadedCensures.sort(function (a, b) {
          return a.veiculacao - b.veiculacao;
        });
        console.log(loadedCensures);
        context.commit('updateCensures', loadedCensures);
        console.log('2.13-b: censures loaded');
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
    },

    // 2.14 load campaign and censuresData subCollection filtered by audioData
    async loadCampaignWithCensuresOnlyAudioData(context, campaignId) {
      context.commit('updateLoading', true);
      console.log('2.14-a: start loding censuresData without audiodata');
      try {
        const docRef = doc(db, 'campaigns', campaignId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const loadedCampaign = docSnap.data();
          loadedCampaign.id = docSnap.id;
          const loadedCensures = [];

          const censuresRef = collection(
            db,
            'campaigns',
            docRef.id,
            'censuresData'
          );

          // const q = query(censuresRef, where('audioData', '!=', null));
          const q = query(censuresRef, orderBy('audioData'));
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach((doc) => {
            const newCensure = {
              ...doc.data(),
              id: doc.id,
            };
            loadedCensures.push(newCensure);
          });

          if (loadedCensures.length)
            loadedCampaign.censuresData = loadedCensures;

          // GAMBI -> APAGAR
          if (loadedCampaign.censuresData) {
            console.log('ENTER GAMBI');
            loadedCampaign.censuresData.forEach((censure) => {
              censure.veiculacao = censure.veiculacao
                .toDate()
                .toLocaleDateString('pt-BR');
            });
          }
          // end GAMBI

          context.commit('updateCampaign', loadedCampaign);
          // console.log(loadedCampaign);
        } else {
          console.log('Não foi possivel ler a campanha especificada.');
        }

        console.log('2.14-b: campaign and censuresData loaded from firestore');
        // console.log(loadedCampaign);
      } catch (err) {
        console.log(err);
      }

      context.commit('updateLoading', false);
    },

    // 2.X xxx
    async xxx(context, payload) {
      context.commit('updateLoading', true);
      console.log('2.X-a: xxx');
      try {
        console.log(payload);
        console.log('2.X-b: xxx');
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
    },

    //3.0 delete existente folder and upload audio file to storage
    async uploadAudioFileToStorage(context, payload) {
      context.commit('updateLoading', true);
      console.log('3.0-a: starting upload file to storage');
      let audioUrl = null;
      try {
        // verifica e deleta se existirem arquivos na pasta com esse idApp
        const folderRef = ref(
          storage,
          `campaigns/${payload.campaignId}/audios/${payload.campaignIdApp}`
        );
        const response = await listAll(folderRef);
        if (response.items.length) {
          response.items.forEach(async (obj) => {
            const itemRef = ref(storage, obj.fullPath);
            await deleteObject(itemRef);
          });
        }
        // faz o upload do arquivo de audio
        const fileName = `campaigns/${payload.campaignId}/audios/${payload.campaignIdApp}/${payload.slugFileName}`;
        const fileRef = ref(storage, fileName);
        const snapshot = await uploadBytes(fileRef, payload.file);
        audioUrl = await getDownloadURL(ref(storage, snapshot.ref));
        console.log('3.0-b: file uploaded');
      } catch (err) {
        console.log(err);
      }
      context.commit('updateLoading', false);
      return audioUrl;
    },

    // clear all this state data
    clearState(context) {
      console.log('starting Cleaning All State');
      context.commit('updateLoading', true);
      context.commit('updateFeedback', null);
      context.commit('updateUser', null);
      context.commit('updateCampaign', null);
      context.commit('updateCampaigns', null);
      context.commit('updateLoading', false);
      console.log('Store state cleaned');
    },
  },

  modules: {},
});
