import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import { auth, onAuthStateChanged } from "@/firebase/firebase";

Vue.config.productionTip = false;
let app = null;

onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
      created() {
        if (user) {
          this.$store.dispatch("loadCurrentUser", user.uid);
          // console.log(user);
        } else {
          console.log("No User");
        }
      },
    }).$mount("#app");
  }
});
