<template>
  <div class="Home">
    <h1 class="text-h5 grey--text text-uppercase">
      Dashboard
    </h1>
  </div>
</template>

<script>
// import { auth } from "@/firebase/firebase";

export default {
  name: "Home",

  components: {
    //
  },

  mounted() {
    // auth.currentUser
    //   .getIdTokenResult()
    //   .then((idTokenResult) => {
    //     console.log(idTokenResult.claims.role);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  },
};
</script>

<style scoped>
.Home {
  padding: 20px;
}
</style>
